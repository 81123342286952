.chart-container {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
}

.table-cell-right {
    text-align: right;
}

#sim-chart.cell {
    flex-grow: 0.25;
    height: 33vh;
    border: 1px solid;
}
#sim-chart.cell-lg {
    flex-grow: 1;
    /* border: 1px solid; */
    height: 100%;
}
#div-container {
    border: none !important;
}
#div-container .svg-content-responsive {
    border: none !important;
}

@media (max-width: 1024px) {
    #sim-chart.cell {
        width: calc(50% - 2px);
    }
}

@media (max-width: 460px) {
    #sim-chart.cell {
        width: calc(100% - 2px);
    }
}
