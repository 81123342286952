.alert-enter {
	opacity: 0;
	transform: scale(0.9);
}

.alert-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 300ms, transform 300ms;
}

.alert-exit {
	opacity: 1;
}

.alert-exit-active {
	opacity: 0;
	transform: scale(0.9);
	transition: opacity 300ms, transform 300ms;
}

.MuiToolbar-gutters {
	padding-right: 0 !important;
	padding-left: 0 !important;
}

.border-dark {
	border-top-color: rgb(112, 112, 112) !important;
	border-top-style: solid !important;
	border-top-width: 1px !important;
}

.border-light {
	border-top-color: #ddd !important;
	border-top-style: solid !important;
	border-top-width: 1px !important;
}

.vs-chart-div {
	display: none;
	width: 33.3%;
	height: 41.2vh !important;
}

.idx-chart-div {
	display: none;
	width: 50%;
	height: 80.2vh !important;
}

.chart-container {
	flex: 1;
}

.tv-lightweight-charts {
	flex: 1;
	height: 100%;
}
