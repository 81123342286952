svg .x-axisChart{
    padding: 2px;
}
svg .x-axisChart .tick text{
    font-size: 7px;
    stroke-width: 0.6;
    stroke-opacity: 0.6;
    font-family: sans-serif;
    transform: rotate(280deg);
}
svg .y-axisChart .tick text{
    font-size: 8px;
    stroke-width: 0.6;
    stroke-opacity: 0.6;
    font-family: sans-serif;
}

svg .textLabel-x{
    font-size: 8px;
}
.gridLine .tick line{
    stroke: #323638;
}

.zero-lineChart{
    stroke: black;
    stroke-width: 1;
    stroke-opacity: 0.5;
}