input:disabled {
	cursor: not-allowed;
}

td {
	border-left: 0px solid !important;
}

#drodpwonIdType .MuiPopover-paper {
	top: calc(100% - 22%) !important;
	transform-origin: unset !important;
	transition: unset !important;
}

#drodpwonSubUidType .MuiPopover-paper {
	top: calc(100% - 30%) !important;
	left: calc(100% - 80%) !important;
	transform-origin: unset !important;
	transition: unset !important;
}

#drodpwonSpreadType .MuiPopover-paper {
	top: calc(100% - 22%) !important;
	left: calc(100% - 80%) !important;
	transform-origin: unset !important;
	transition: unset !important;
}
