.cell-status {
	display: flex;
	gap: 0.5em;

	text-transform: capitalize;
	font-size: 1.25rem;
}

.cell-status-success {
	color: #43a047;
}

.cell-status-progress {
	color: #4891f2;
}

.cell-status-fail {
	color: #ea3131;
}

.cell-status-not_loaded {
	color: #dcdcdc;
}
