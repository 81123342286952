body {
	font-family: Roboto;
}

.react-autosuggest__container {
	position: relative;
}

.react-autosuggest__input,
.symbolValue {
	height: 21px;
	padding: 0px 8px;
	font-family: Roboto;
	font-weight: 300;
	font-size: 10px;
	box-shadow: 3px 0px 6px #00000033 !important;
	text-transform: uppercase;
	border: 1px #5e5e5e solid !important;
	background-color: #212121 !important;
	color: #cccccc;
	border-radius: 3px;
}

.react-autosuggest__input--focused {
	outline: none;
}

.react-autosuggest__input--open {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
	display: none;
}

.react-autosuggest__suggestions-container--open {
	display: block;
	position: absolute;
	top: 20px;
	width: 200px;
	box-shadow: 3px 0px 6px #00000033 !important;
	background-color: #fff;
	font-family: Roboto;
	font-weight: 300;
	font-size: 14px;
	z-index: 2;
}

.react-autosuggest__suggestions-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
	max-height: 200px;
	overflow-y: auto;
	z-index: 999999;
	border-radius: 3px;
}

.react-autosuggest__suggestion {
	cursor: pointer;
	padding: 8px 12px;
}

.react-autosuggest__suggestion--highlighted {
	background-color: #ffffff40;
}

.newSymbolValue {
	box-shadow: 3px 0px 6px #00000033 !important;
	font-size: 11px !important;
	width: 100%;
	color: #fff;
	border-radius: 3px;
	padding: 6px 8px;
}
